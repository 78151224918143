import { createContext, useContext } from 'react'
import { configResponsive, useResponsive } from 'ahooks'
import PropTypes from 'prop-types'
import { breakpoints } from '../assets/styles/config'

configResponsive({
  isSm: parseInt(breakpoints.sm, 10),
  isMd: parseInt(breakpoints.md, 10),
  isLg: parseInt(breakpoints.lg, 10),
  isXl: parseInt(breakpoints.xl, 10),
  isXxl: parseInt(breakpoints.xxl, 10),
  isXxxl: parseInt(breakpoints.xxxl, 10),
})

const ResponsiveContext = createContext()
const isClient = typeof window !== 'undefined'
const isTouch = isClient ? 'ontouchstart' in document.documentElement : false

export function ResponsiveProvider({ children }) {
  const responsive = useResponsive()

  return (
    <ResponsiveContext.Provider value={{ responsive, isTouch }}>
      {children}
    </ResponsiveContext.Provider>
  )
}

ResponsiveProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function useResponsiveContext() {
  return useContext(ResponsiveContext)
}
