import styled, { keyframes } from 'styled-components'
import { down } from 'styled-breakpoints'
import { ellipsis } from 'polished'
import { Spin as Hamburger } from 'hamburger-react'
import { useState, useRef, useEffect } from 'react'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Container, { StyledContainer } from '../Container'
import SiteMobileMenu from './SiteMobileMenu'
import NextLink from '../Next/NextLink'
import Location from '../../assets/icons/location.svg?sprite'
import { useResponsiveContext } from '../../context/ResponsiveContext'
import foodtrack from '../../features/Foodtrack/FoodtrackSlice'

const menu = [
  {
    id: 1,
    name: 'Производство',
    path: '/production',
  },
  {
    id: 2,
    name: 'Рецепты',
    path: '/recipes',
  },
  {
    id: 3,
    name: 'Ramen box',
    path: '/box',
  },
  {
    id: 4,
    name: 'Фудтрак',
    path: '/foodtrack',
  },
  {
    id: 5,
    name: 'Контакты',
    path: '/buy',
  },
]

export default function SiteHeader() {
  const [isOpenedMenu, setStateMenu] = useState(false)
  const { responsive } = useResponsiveContext()
  const mobileRef = useRef()
  const router = useRouter()
  const currentPlace = useSelector(foodtrack.getCurrent)

  function toggleMenu(state) {
    if (!responsive.isLg) {
      if (state) {
        disableBodyScroll(mobileRef.current)
      } else {
        enableBodyScroll(mobileRef.current)
      }
    }

    setStateMenu(state)
  }

  useEffect(() => {
    if (responsive.isLg) {
      enableBodyScroll(mobileRef.current)
    } else if (isOpenedMenu) {
      disableBodyScroll(mobileRef.current)
    }
  }, [responsive])

  useEffect(() => {
    return () => clearAllBodyScrollLocks()
  }, [])

  useEffect(() => {
    if (isOpenedMenu && !responsive.isLg) {
      toggleMenu(false)
    }
  }, [router.asPath])

  return (
    <StyledHeader role="banner">
      <Container>
        <StyledHeaderInner>
          <StyledHeaderLeft>
            <StyledHeaderNav>
              {menu.slice(0, 3).map((item) => (
                <NextLink key={item.id} href={item.path}>
                  <StyledHeaderNavLink>{item.name}</StyledHeaderNavLink>
                </NextLink>
              ))}
            </StyledHeaderNav>
          </StyledHeaderLeft>
          <StyledHeaderCenter>
            <NextLink href="/">
              <StyledHeaderLogoLink>
                <img
                  width={180}
                  height={62}
                  src={require('../../assets/images/logo-ramen.svg')}
                  alt="Ramen"
                />
              </StyledHeaderLogoLink>
            </NextLink>
          </StyledHeaderCenter>
          <StyledHeaderRight>
            <StyledHeaderNav>
              {menu.slice(3, 5).map((item) => (
                <NextLink key={item.id} href={item.path}>
                  <StyledHeaderNavLink>{item.name}</StyledHeaderNavLink>
                </NextLink>
              ))}
              <StyledHeaderPlace>
                <StyledHeaderPlaceDate empty={!currentPlace}>
                  {moment(new Date()).format('DD.MM.YY')}
                </StyledHeaderPlaceDate>
                {currentPlace && (
                  <NextLink href="/foodtrack">
                    <StyledHeaderPlaceLink>
                      <StyledHeaderPlaceIcon>
                        <Location width={23} height={23} />
                      </StyledHeaderPlaceIcon>
                      <StyledHeaderPlaceText>
                        {currentPlace?.name}
                      </StyledHeaderPlaceText>
                    </StyledHeaderPlaceLink>
                  </NextLink>
                )}
              </StyledHeaderPlace>
            </StyledHeaderNav>
          </StyledHeaderRight>
          <StyledHeaderBurger>
            <Hamburger
              direction="left"
              toggled={isOpenedMenu}
              toggle={toggleMenu}
              size={30}
              duration={0.3}
            />
          </StyledHeaderBurger>
        </StyledHeaderInner>
      </Container>

      <StyledHeaderMobileMenu isOpen={isOpenedMenu}>
        <StyledHeaderMobileMenuInner ref={mobileRef}>
          <SiteMobileMenu items={menu} />
        </StyledHeaderMobileMenuInner>
      </StyledHeaderMobileMenu>
    </StyledHeader>
  )
}

SiteHeader.defaultProps = {}

SiteHeader.propTypes = {}

const StyledHeaderMobileMenuInner = styled.div`
  flex-grow: 1;
  max-height: 100%;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
`

const StyledHeaderPlaceText = styled.span`
  ${ellipsis('100%')};
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  ${down('lg')} {
    font-size: 14px;
    line-height: 17px;
  }

  ${down('md')} {
    font-size: 14px;
    line-height: 17px;
  }
`
const StyledHeaderPlaceIcon = styled.div`
  margin-top: 1px;
  flex: 0 0 auto;
  color: ${(props) => props.theme.red};
  margin-right: 5px;

  svg {
    ${down('md')} {
      width: 18px;
      height: 18px;
    }
  }
`
const StyledHeaderPlaceLink = styled.a`
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 2px solid ${(props) => props.theme.colorSecondary};
  align-items: center;
  font-size: 0;
  line-height: 0;
  padding-bottom: 5px;
  color: ${(props) => props.theme.white};
  text-decoration: none;
  transition: ${(props) => props.theme.transitionTime};

  &:hover {
    color: ${(props) => props.theme.colorSecondary};
  }
`
const StyledHeaderPlaceDate = styled.div`
  font-size: 15px;
  line-height: 18px;
  color: ${(props) => props.theme.white};
  margin-bottom: ${(props) => (props.empty ? '0' : '4px')};

  ${down('xxl')} {
    margin-bottom: 0;
  }

  ${down('md')} {
    display: none;
  }
`
const StyledHeaderPlace = styled.div`
  text-align: center;
  max-width: 150px;
`

const StyledHeader = styled.header`
  font-family: ${(props) => props.theme.fontFamilySecondary};
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${(props) => props.theme.zIndexHeader};
  background-color: rgba(0, 0, 0, 0.9);
  color: ${(props) => props.theme.white};

  ${StyledContainer} {
    width: 100%;
  }
`

const rotate = keyframes`
  from {
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`

const StyledHeaderMobileMenu = styled.div`
  display: none;
  position: absolute;
  background-color: ${(props) => props.theme.colorPrimary};
  width: 100%;
  top: 65px;
  height: calc(100vh - 65px);
  flex-direction: column;
  animation: ${rotate} ${(props) => props.theme.transitionTime};

  ${down('md')} {
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  }
`
const StyledHeaderInner = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`
const StyledHeaderBurger = styled.div`
  display: none;
  flex: 0 0 auto;
  margin-left: 15px;
  margin-right: -8px;
  margin-top: -2px;

  ${down('md')} {
    display: block;
  }
`
const StyledHeaderLeft = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 40%;

  ${down('md')} {
    display: none;
  }
`
const StyledHeaderCenter = styled.div`
  width: 20%;
  text-align: center;
  font-size: 0;
  line-height: 0;

  ${down('xxl')} {
    // width: 15%;
  }

  ${down('md')} {
    width: 115px;
    margin-right: 15px;
  }
`
const StyledHeaderRight = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 40%;

  ${down('md')} {
    width: auto;
    margin-left: auto;
  }
`
const StyledHeaderNav = styled.nav`
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 85px;
  margin-top: -4px;

  ${down('xxl')} {
    height: 69px;
  }

  ${down('md')} {
    height: 69px;
  }
`
const StyledHeaderNavLink = styled.a`
  padding: 10px;
  text-decoration: none;
  display: inline-block;
  color: ${(props) => props.theme.white};
  font-weight: 700;
  font-size: 19px;
  line-height: 22px;
  transition: ${(props) => props.theme.transitionTime};

  &:hover,
  &.next-link-active {
    color: ${(props) => props.theme.colorSecondary};
  }

  ${down('lg')} {
    font-size: 17px;
    line-height: 20px;
  }

  ${down('md')} {
    display: none;
  }
`
const StyledHeaderLogoLink = styled.a`
  display: inline-flex;

  img {
    height: 100%;
    ${down('xxl')} {
      width: 145px;
    }
  }
`
