import styled from 'styled-components'
import { up } from 'styled-breakpoints'

export default function Develop() {
  return (
    <StyledDevelop
      target="_blank"
      href="https://websecret.by/?utm_source=ramen&amp;utm_medium=link&amp;utm_campaign=ramen"
    >
      <StyledDevelopText>Разработка сайта – </StyledDevelopText>

      <StyledDevelopLogo>
        <img
          width={60}
          height={30}
          src={require('../assets/images/logo-ws.svg')}
          alt="WebSecret"
        />
      </StyledDevelopLogo>
    </StyledDevelop>
  )
}

Develop.defaultProps = {}

Develop.propTypes = {}

const StyledDevelop = styled.a`
  display: inline-flex;
  padding: 8px;
  margin: -8px;
  font-size: 0;
  line-height: 0;
  transition: ${(props) => props.theme.transitionTime};
  text-decoration: none;
  color: ${(props) => props.theme.black};
  flex-wrap: nowrap;

  &:hover {
    opacity: ${(props) => props.theme.opacity};
  }
`

const StyledDevelopText = styled.span`
  display: block;
  font-size: 10px;
  line-height: 13px;
  align-self: center;

  ${up('lg')} {
    font-size: 12px;
    line-height: 16px;
  }

  ${up('xl')} {
    font-size: 14px;
    line-height: 18px;
  }
`

const StyledDevelopLogo = styled.span`
  display: block;
  flex: 0 0 auto;
  margin-left: 10px;

  ${up('md')} {
    img {
      width: 70px;
      height: 36px;
    }
  }

  ${up('lg')} {
    img {
      width: 80px;
      height: 41px;
    }
  }

  ${up('xl')} {
    img {
      width: 100px;
      height: 52px;
    }
  }
`
