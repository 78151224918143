import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { useUserAgent } from 'next-useragent'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import _debounce from 'lodash/debounce'
import { useRouter } from 'next/router'
import { motion } from 'framer-motion'
import NextProgress from '../components/Next/NextProgress'
import { breakpoints, colors, theme } from '../assets/styles/config'
import Page from '../layouts/Page'
import createStore from '../store'
import { ResponsiveProvider } from '../context/ResponsiveContext'
import redirect from '../services/helpers/redirect'
import 'react-day-picker/lib/style.css'
import 'swiper/swiper-bundle.min.css'
import foodtrack from '../features/Foodtrack/FoodtrackSlice'

const styleConfig = {
  breakpoints,
  ...colors,
  ...theme,
}

function initVhFix() {
  function vhFix() {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  window.addEventListener('resize', _debounce(vhFix, 150))

  vhFix()

  return () => window.removeEventListener('resize', vhFix)
}

const defaultTitle = 'Главная'
const defaultDescription = 'Рамен'

function App({ Component, pageProps, initialReduxState }) {
  const store = createStore(initialReduxState)
  const layout = pageProps?.layout || 'default'
  const metaTitle = pageProps?.metaTitle || defaultTitle
  const metaDescription = pageProps?.metaDescription || defaultDescription
  const titlePage = pageProps?.titlePage || null
  const router = useRouter()

  useEffect(initVhFix, [])

  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={styleConfig}>
          <ResponsiveProvider>
            <Page
              metaTitle={metaTitle}
              metaDescription={metaDescription}
              layout={layout}
              titlePage={titlePage}
            >
              <NextProgress />
              <motion.div
                key={router.route}
                initial="pageInitial"
                animate="pageAnimate"
                variants={{
                  pageInitial: {
                    opacity: 0,
                  },
                  pageAnimate: {
                    opacity: 1,
                  },
                }}
              >
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <Component {...pageProps} />
              </motion.div>
            </Page>
          </ResponsiveProvider>
        </ThemeProvider>
      </Provider>
    </>
  )
}

App.getInitialProps = async ({ Component, ctx }) => {
  const isServer = typeof window === 'undefined'

  if (isServer) {
    const { browser } = useUserAgent(ctx.req.headers['user-agent'])

    if (browser === 'Internet Explorer') {
      redirect('https://outdatedbrowser.com/ru', ctx.res)
    }
  }

  let pageProps = {}
  const reduxStore = createStore()

  await reduxStore.dispatch(foodtrack.fetchCurrent())

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps({ ...ctx, reduxStore })
  }

  return {
    pageProps,
    initialReduxState: reduxStore.getState(),
  }
}

App.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.objectOf(PropTypes.any).isRequired,
  initialReduxState: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default App
