import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createApi from '../services/api'
import products from '../features/Products/ProductsSlice'
import main from '../features/Main/MainSlice'
import foodtrack from '../features/Foodtrack/FoodtrackSlice'
import box from '../features/Box/BoxSlice'

let globalStore

function initializeStore(preloadedState) {
  const extraArgument = {}

  const store = configureStore({
    reducer: {
      products: products.reducer,
      main: main.reducer,
      foodtrack: foodtrack.reducer,
      box: box.reducer,
    },
    preloadedState,
    middleware: getDefaultMiddleware({
      thunk: {
        extraArgument,
      },
    }),
    devTools: process.env.NODE_ENV !== 'production',
  })

  extraArgument.api = createApi(store)

  return store
}

export default function createStore(initialState) {
  if (typeof window === 'undefined') {
    return initializeStore(initialState)
  }

  if (!globalStore) {
    globalStore = initializeStore(initialState)
  }
  return globalStore
}
