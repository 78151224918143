// UI

export const breakpoints = {
  sm: '480px',
  md: '768px',
  lg: '1024px',
  xl: '1200px',
  xxl: '1420px',
  xxxl: '1890px',
}

export const colors = {
  black: 'hsl(0, 0%, 0%)',
  grayDarkest: 'hsl(0, 0%, 10%)',
  grayDarker: 'hsl(0, 0%, 20%)',
  grayDarken: 'hsl(0, 0%, 30%)',
  grayDark: 'hsl(0, 0%, 40%)',
  gray: 'hsl(0, 0%, 50%)',
  grayLight: 'hsl(0, 0%, 60%)',
  grayLighten: 'hsl(0, 0%, 70%)',
  grayLighter: 'hsl(0, 0%, 80%)',
  grayLightest: 'hsl(0, 0%, 90%)',
  white: 'hsl(0, 0%, 100%)',

  green: '#1B4214',
  yellow: '#FFBE00',
  pink: '#FF7878',
  red: '#EB5757',
}

export const theme = {
  colorPrimary: colors.green,
  colorSecondary: colors.yellow,
  colorDanger: colors.pink,

  textColor: colors.black,
  textColorMuted: colors.grayLightest,

  bodyBg: colors.white,
  placeholderColor: colors.grayLightest,
  borderColor: '#C4C4C4',

  fontFamily: `'Source Code Pro', -apple-system, BlinkMacSystemFont, 'Roboto', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', 'Arial', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
  fontFamilySecondary: `'Montserrat', -apple-system, BlinkMacSystemFont, 'Roboto', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', 'Arial', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
  fontSize: '16px',
  lineHeight: '21px',

  containerMaxWidth: '1360px',
  containerGutters: '15px',
  containerGuttersMd: '40px',

  mobileHeaderHeight: '65px',
  desktopHeaderHeight: '85px',

  transitionTime: '0.15s ease',
  transitionTimeSlow: '0.3s ease',
  transitionTimePage: '.1s',

  opacity: '0.7',

  zIndexNProgress: 110,
  zIndexModal: 100,
  zIndexTooltip: 90,
  zIndexHeader: 5,
}

// Other
