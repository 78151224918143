import { createSlice } from '@reduxjs/toolkit'

const foodtrackSlice = createSlice({
  name: 'foodtrack',
  initialState: {
    current: null,
    foodtrack: null,
    menu: null,
  },
  reducers: {
    foodtrackCurentAdded(state, { payload: { foodtrackCurrent } }) {
      state.current = foodtrackCurrent
    },
    foodtrackAdded(state, { payload: { foodtrack } }) {
      state.foodtrack = foodtrack
    },
    menuAdded(state, { payload: { menu }}) {
      state.menu = menu
    }
  },
})

const getFoodtrack = (state) => {
  return state.foodtrack.foodtrack
}

const getCurrent = (state) => {
  return state.foodtrack.current
}

const getMenu = (state) => {
  return state.foodtrack.menu
}

const fetchMenu = () => async (dispatch, getState, { api }) => {
  const menu = getMenu(getState())

  if (menu) {
    return menu
  }

  const response = await api.menu()
  dispatch(foodtrackSlice.actions.menuAdded({ menu: response }))

  return response
}

const fetchFoodtrack = () => async (dispatch, getState, { api }) => {
  const foodtrack = getFoodtrack(getState())

  if (foodtrack) {
    return foodtrack
  }

  const response = await api.foodtrack()
  dispatch(foodtrackSlice.actions.foodtrackAdded({ foodtrack: response }))

  return response
}

const fetchCurrent = () => async (dispatch, getState, { api }) => {
  const current = getCurrent(getState())

  if (current) {
    return current
  }

  const response = await api.foodtrackCurrent()
  dispatch(
    foodtrackSlice.actions.foodtrackCurentAdded({
      foodtrackCurrent: response.location,
    }),
  )

  return response
}

const sendMessage = (data) => async (dispatch, getState, { api }) => {
  const response = await api.feedback(data)

  return response.result === 'success'
}

export default {
  sendMessage,
  getFoodtrack,
  getCurrent,
  fetchFoodtrack,
  fetchMenu,
  fetchCurrent,
  reducer: foodtrackSlice.reducer,
  getMenu,
}
