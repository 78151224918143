import styled from 'styled-components'
import PropTypes from 'prop-types'
import NextLink from '../Next/NextLink'
// import { up } from 'styled-breakpoints'

export default function SiteMobileMenu({ items }) {
  return (
    <StyledMobileMenu>
      <StyledMobileMenuList>
        {items.map((item) => (
          <NextLink key={item.id} href={item.path}>
            <StyledHeaderMenuLink>{item.name}</StyledHeaderMenuLink>
          </NextLink>
        ))}
      </StyledMobileMenuList>
    </StyledMobileMenu>
  )
}

SiteMobileMenu.defaultProps = {}

SiteMobileMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
}

const StyledMobileMenu = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledMobileMenuList = styled.div`
  padding: 20px;
`
const StyledHeaderMenuLink = styled.a`
  padding: 8px;
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  text-decoration: none;
  color: ${(props) => props.theme.colorSecondary};

  &:not(:last-child) {
    margin-bottom: 7px;
  }

  &.next-link-active {
    color: ${(props) => props.theme.white};
  }
`
