import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import Develop from '../Develop'
import Container from '../Container'
import NextLink from '../Next/NextLink'

export default function SiteFooter() {
  const copy = `© ${new Date().getFullYear()} Ramen.by. All rights reserved`
  return (
    <StyledFooter role="contentinfo">
      <StyledFooterInner>
        <StyledFooterTop>
          <Container>
            <StyledFooterTopInner>
              <StyledFooterTopLeft>
                <NextLink>
                  <StyledFooterLogoLink href="/">
                    <img
                      width={140}
                      height={265}
                      src={require('../../assets/images/footer-logo.svg')}
                      alt="Ramen"
                    />
                  </StyledFooterLogoLink>
                </NextLink>
              </StyledFooterTopLeft>

              <StyledFooterTopRight>
                <StyledFooterTopRightGrid>
                  <StyledFooterTopRightCell>
                    <StyledFooterSubtitle>Контакты</StyledFooterSubtitle>

                    <StyledFooterContactsText>
                      <StyledFooterContactLinks>
                        <StyledFooterContactLink href="tel:+375445157362">
                          +375 (44) 515-73-62
                        </StyledFooterContactLink>

                      </StyledFooterContactLinks>
                    </StyledFooterContactsText>
                  </StyledFooterTopRightCell>



                  <StyledFooterTopRightCell>
                    <StyledFooterSubtitle>Адрес</StyledFooterSubtitle>

                    <StyledFooterContactsText>
                      Минск, ул. Богдановича, 78
                    </StyledFooterContactsText>
                  </StyledFooterTopRightCell>

                  <StyledFooterTopRightCell>
                    <StyledFooterSubtitle>Режим работы</StyledFooterSubtitle>

                    <StyledFooterContactsText>
                      <div>Доставка Ramen box 11.00 - 23.00</div>
                    </StyledFooterContactsText>
                  </StyledFooterTopRightCell>

                  <StyledFooterTopRightCell>
                    <StyledFooterSubtitle>Соц. сети</StyledFooterSubtitle>

                    <StyledFooterContactsText>
                      <StyledFooterContactLinks>
                        <StyledFooterContactLink
                          target="_blank"
                          href="https://instagram.com/ramen_by_ronin"
                        >
                          Instagram
                        </StyledFooterContactLink>

                        <StyledFooterContactLink
                          target="_blank"
                          href="https://www.facebook.com/Ramenby-110449423841726/"
                        >
                          Facebook
                        </StyledFooterContactLink>
                      </StyledFooterContactLinks>
                    </StyledFooterContactsText>
                  </StyledFooterTopRightCell>
                </StyledFooterTopRightGrid>
              </StyledFooterTopRight>
            </StyledFooterTopInner>
          </Container>
        </StyledFooterTop>

        <Container>
          <StyledFooterBottom>
            <StyledFooterBottomLeft>
              <StyledFooterText>
                <div>НАШИ РЕКВИЗИТЫ:</div>
                <div>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Общество с ограниченной ответственностью "ПИНФУД сервис".
                  382950385000 ОКПО УНП 192677158. 14.07.2016. Минский
                  горисполком. 220100, г. Минск, ул.М. Богдановича д.78, 24H
                </div>
                <div>
                  р/с BY63TECN 30120270700140000000 ОАО «Технобанк», код
                  TECNBY22 г.Минск, ул. Кропоткина, 44
                </div>
              </StyledFooterText>

              <StyledFooterText mutted>{copy}</StyledFooterText>
            </StyledFooterBottomLeft>

            <StyledFooterBottomRight>
              <Develop />
            </StyledFooterBottomRight>
          </StyledFooterBottom>
        </Container>
      </StyledFooterInner>
    </StyledFooter>
  )
}

SiteFooter.defaultProps = {}

SiteFooter.propTypes = {}

const StyledFooterLogoLink = styled.a`
  font-size: 0;
  line-height: 0;
  display: inline-flex;
  text-decoration: none;
`

const StyledFooterContactLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const StyledFooterContactLink = styled.a`
  display: inline-flex;
  color: inherit;
  text-decoration: none;
  padding: 5px;
  margin: -5px;

  &:not(:last-child) {
    margin-bottom: 0px;
  }

  &:hover {
    text-decoration: underline;
  }
`

const StyledFooterContactsText = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.white};

  ${up('md')} {
    font-size: 16px;
    line-height: 20px;
  }

  ${up('lg')} {
    font-size: 16px;
    line-height: 20px;
  }

  ${up('xl')} {
    font-size: 18px;
    line-height: 22px;
  }
`

const StyledFooterTopInner = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;

  ${up('md')} {
    margin-left: 0px;
    margin-right: 0px;
  }
`

const StyledFooterTopLeft = styled.div`
  width: 50%;
  max-width: 50%;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;

  ${up('md')} {
    padding-left: 0;
    padding-right: 0;
    max-width: none;
    width: 100px;
    flex: 0 0 auto;
    margin-right: 40px;
  }

  ${up('lg')} {
    width: 120px;
    margin-right: 70px;
  }

  ${up('xl')} {
    width: 130px;
    margin-right: 0px;
    margin-left: 100px;
    margin-right: 150px;
  }
`

const StyledFooterTopRight = styled.div`
  width: 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;

  ${up('md')} {
    padding-left: 0;
    padding-right: 0;
    width: auto;
    max-width: none;
  }
`

const StyledFooterTopRightGrid = styled.div`
  ${up('md')} {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -25px;
  }

  ${up('xl')} {
    margin-bottom: -75px;
  }
`
const StyledFooterTopRightCell = styled.div`
  ${up('md')} {
    width: 33.333333%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 25px;
  }

  ${up('xl')} {
    padding-bottom: 75px;
  }

  &:not(:last-child) {
    margin-bottom: 20px;

    ${up('md')} {
      margin-bottom: 0;
    }
  }

  &:nth-child(1) {
    ${up('md')} {
      order: 0;
      width: 40%;
    }
  }

  &:nth-child(2) {
    ${up('md')} {
      order: 4;
      width: 40%;
    }
  }

  &:nth-child(3) {
    ${up('md')} {
      order: 5;
      width: 40%;
    }
  }

  &:nth-child(4) {
    ${up('md')} {
      order: 1;
      width: 40%;
    }
  }

  &:nth-child(5) {
    ${up('md')} {
      order: 3;
      width: 20%;
    }
  }
`

const StyledFooterSubtitle = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colorSecondary};
  margin-bottom: 10px;

  ${up('md')} {
    font-size: 16px;
    line-height: 20px;
  }

  ${up('lg')} {
    font-size: 16px;
    line-height: 20px;
  }

  ${up('xl')} {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 22px;
  }
`

const StyledFooterTop = styled.div`
  font-family: ${(props) => props.theme.fontFamilySecondary};
  color: ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.colorPrimary};
  padding-top: 65px;
  padding-bottom: 57px;

  ${up('md')} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  ${up('lg')} {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  ${up('xl')} {
    padding-top: 70px;
    padding-bottom: 70px;
  }
`
const StyledFooter = styled.footer`
  overflow: hidden;
`
const StyledFooterInner = styled.div``
const StyledFooterText = styled.div`
  color: ${(props) => (props.mutted ? props.theme.gray : props.theme.black)};
  font-size: 10px;
  line-height: 13px;
  font-family: ${(props) => props.theme.fontFamilySecondary};

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  ${up('md')} {
    font-size: 12px;
    line-height: 16px;
  }

  ${up('lg')} {
    font-size: 12px;
    line-height: 16px;
  }

  ${up('xl')} {
    font-size: 14px;
    line-height: 18px;
  }
`
const StyledFooterBottom = styled.div`
  padding-top: 28px;
  padding-bottom: 21px;

  ${up('md')} {
    padding-top: 31px;
    padding-bottom: 16px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  ${up('lg')} {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  ${up('xl')} {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`
const StyledFooterBottomLeft = styled.div`
  margin-bottom: 12px;

  ${up('md')} {
    max-width: 780px;
    margin-bottom: 0;
    margin-right: 50px;
  }
`
const StyledFooterBottomRight = styled.div`
  ${up('md')} {
    padding-top: 10px;
    flex: 0 0 auto;
  }
`
