import { useEffect } from 'react'
import NProgress from 'nprogress'
import Router from 'next/router'
import { theme } from '../../assets/styles/config'

export default function NextProgress() {
  const routeChangeStart = () => {
    NProgress.start()
  }

  const routeChangeEnd = () => {
    NProgress.done()
  }

  const routeChangeError = (err, url) => {
    if (err.cancelled) {
      console.log(`Route to ${url} was cancelled!`)
    }
  }

  useEffect(() => {
    Router.events.on('routeChangeStart', routeChangeStart)
    Router.events.on('routeChangeComplete', routeChangeEnd)
    Router.events.on('routeChangeError', routeChangeError)

    return () => {
      Router.events.off('routeChangeStart', routeChangeStart)
      Router.events.off('routeChangeComplete', routeChangeEnd)
      Router.events.off('routeChangeError', routeChangeError)
    }
  }, [])

  return (
    <style jsx global>
      {`
        #nprogress {
          position: relative;
          z-index: ${theme.zIndexNProgress};
          pointer-events: none;
        }

        #nprogress .bar {
          background: ${theme.colorSecondary};

          position: fixed;
          z-index: ${theme.zIndexNProgress};
          top: 0;
          left: 0;

          width: 100%;
          height: 3px;
        }

        #nprogress .peg {
          display: block;
          position: absolute;
          right: 0px;
          width: 100px;
          height: 100%;
          box-shadow: 0 0 10px ${theme.colorSecondary},
            0 0 5px ${theme.colorSecondary};
          opacity: 1;
          transform: rotate(3deg) translate(0px, -4px);
        }
      `}
    </style>
  )
}
