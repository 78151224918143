import axios from 'axios'
import getConfig from 'next/config'
import qs from 'qs'
import { serialize } from 'object-to-formdata'

axios.defaults.paramsSerializer = (params) => qs.stringify(params)

const { publicRuntimeConfig } = getConfig()
const isClient = () => typeof window !== 'undefined'
const API_URL = isClient()
  ? publicRuntimeConfig.browserApiUrl
  : publicRuntimeConfig.apiUrl

const fullUrl = (url, slug) => {
  return slug ? `${API_URL}/${url}/${slug}` : `${API_URL}/${url}`
}

export default function createApi() {
  const getHeaders = () => {
    return undefined
  }

  const get = (url) => (query = {}) => {
    const {
      slug = '',
      cancelToken = axios.CancelToken.source().token,
      ...params
    } = query

    return axios
      .get(fullUrl(url, slug), { cancelToken, params, headers: getHeaders() })
      .then((response) => response.data)
      .catch((error) => {
        const { config } = error.response
        // eslint-disable-next-line no-shadow
        const { method, url, params } = config
        return {
          result: 'error',
          statusCode: error.response.status,
          config: { method, url, params },
          data: error.response.data,
        }
      })
  }

  const post = (url) => async (query = {}, withFile = false) => {
    const {
      slug = '',
      cancelToken = axios.CancelToken.source().token,
      ...data
    } = query

    try {
      const headers = () => {
        const formData = { 'Content-Type': 'multipart/form-data' }
        if (withFile) {
          return getHeaders()
            ? { ...getHeaders(), ...formData }
            : { ...formData }
        }

        return getHeaders()
      }

      const response = await axios.post(
        fullUrl(url, slug),
        withFile ? serialize(data) : data,
        {
          cancelToken,
          headers: headers(),
        },
      )

      return response.data
    } catch (e) {
      if (url === 'partner_request/password_reset') {
        // eslint-disable-next-line no-throw-literal
        throw {
          errors: {
            login: ['some_error'],
          },
        }
      } else {
        throw e.response ? e.response.data : e
      }
    }
  }

  return {
    foodtrack: get('foodtrack'),
    menu: get('menu'),
    foodtrackCurrent: get('foodtrack/current-location'),
    slider: get('slider'),
    recipes: get('recipes'),
    feedback: post('feedback'),
    box: get('ramen-boxes'),
  }
}
