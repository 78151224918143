import Head from 'next/head'
import PropTypes from 'prop-types'
import LayoutsDefault from './Default'
import { colors } from '../assets/styles/config'

function Page({ layout, children, metaTitle, metaDescription, titlePage }) {
  const title = `${metaTitle} | Рамен`

  const layouts = {
    default: (
      <LayoutsDefault title={titlePage || metaTitle}>{children}</LayoutsDefault>
    ),
  }

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no"
        />
        <meta
          name="description"
          property="description"
          content={metaDescription}
        />
        <meta name="format-detection" content="telephone=no" />
        <meta name="format-detection" content="date=no" />
        <meta name="format-detection" content="address=no" />
        <meta name="format-detection" content="email=no" />
        <meta name="google" content="notranslate" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta httpEquiv="cleartype" content="on" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="msapplication-TileColor" content="#f5f6f8" />
        <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
        <meta name="theme-color" content={colors.black} />

        <link rel="author" href="/humans.txt" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-chrome-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link
          rel="manifest"
          href="/site.webmanifest"
          crossOrigin="use-credentials"
        />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      </Head>

      {layouts[layout] || layouts.default}
    </>
  )
}

Page.defaultProps = {
  titlePage: null,
}

Page.propTypes = {
  metaTitle: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  titlePage: PropTypes.string,
}

export default Page
