import { createSlice } from '@reduxjs/toolkit'

const BoxSlice = createSlice({
  name: 'box',
  initialState: {
    box: [],
  },
  reducers: {
    boxAdded(state, { payload: { box } }) {
      state.box = box
    },
  },
})

const getBox = (state) => {
  return state.box.box.length ? state.box.box : null
}

const fetchBox = () => async (dispatch, getState, { api }) => {
  const box = getBox(getState())

  if (box) {
    return box
  }

  const response = await api.box()
  dispatch(BoxSlice.actions.boxAdded({ box: response }))

  return response
}

export default {
  getBox,
  fetchBox,
  reducer: BoxSlice.reducer,
}
