import PropTypes from 'prop-types'
import styled from 'styled-components'
import SiteFooter from '../components/Site/SiteFooter'
import SiteHeader from '../components/Site/SiteHeader'

export default function Default({ children }) {
  return (
    <>
      <StyledContent>
        <SiteHeader />
        <StyledMain>{children}</StyledMain>
      </StyledContent>
      <StyledFooterWrapper>
        <SiteFooter />
      </StyledFooterWrapper>
    </>
  )
}

Default.defaultProps = {}

Default.propTypes = {
  children: PropTypes.node.isRequired,
}

const StyledContent = styled.div`
  flex-grow: 1;
`
const StyledMain = styled.main``
const StyledFooterWrapper = styled.div`
  flex-shrink: 0;
`
